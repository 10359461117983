import React from "react";
// import clsx from "clsx";
import { ResourceHero, MailchimpForm, ShareContainer } from "@molecules";
import { Container } from "@atoms";
import { SITE } from "@constants";
import ContentBuilder from "./ContentBuilder";

const ResourceBody = ({ type, hero, blocks, form, url }) => {
  return (
    <section className="mb-16 mt-32 md:mb-24 md:mt-48">
      <Container variant="xl">
        <article className="flex flex-wrap gap-16 xl:flex-nowrap xl:gap-0">
          <div className="mx-auto w-full lg:flex-1">
            <ResourceHero {...hero} type={type} />
            <ContentBuilder blocks={blocks} />
            <ShareContainer share={{ url }} />
          </div>
          {/* form */}
          {type !== "whitepaper" && (
            <div className="w-full pl-4 pr-4 sm:pl-0 sm:pr-8 md:pl-0 md:pr-10 lg:mt-14 lg:pl-12 lg:pr-12 xl:w-1/3 xl:pl-0 xl:pr-20">
              <MailchimpForm
                {...form}
                heading={
                  SITE === "default"
                    ? "Newsletter Signup"
                    : "Join the Causie Community"
                }
              />
            </div>
          )}
        </article>
      </Container>
    </section>
  );
};

ResourceBody.defaultProps = {};

export default ResourceBody;
